<template>
    <v-card hover
        :color="level == 0 ? '' : ''"
        class="my-1"
        :width="imageWidth+'em'">

        <v-dialog v-if="level > 0"
            v-model="info"
            width="500"
            >
            <template v-slot:activator="{ on, attrs }">

                <v-btn-toggle
                    borderless
                    style="position: absolute; z-index: 2; right: 0;"
                    dense
                    rounded
                    class="px-3 py-1"
                    background-color="rgba(255, 255, 255, 0.2)">
                    <v-btn v-for="(item, idx) in health.slice(0, 4)"
                        :key="idx"
                        icon
                        color="rgba(255, 255, 255, 0.1)"
                        small
                        class="ma-0 pa-0"
                        style="min-width: unset;"
                        v-bind="attrs"
                        v-on="on">
                        <v-icon small :color="item.level === 2 ? 'error' : 'warning'">
                            {{ `mdi-${item.icon}` }}
                        </v-icon>
                    </v-btn>
                    <div v-if="health.length > 4" style="color:rgba(0,0,0,0.5);font-weight: bold;">+{{ health.length - 4 }}</div>
                </v-btn-toggle>
            </template>

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    <v-icon class="mr-2" large :color="level === 2 ? 'error' : 'warning'">{{ level === 2 ? 'mdi-alert-octagon' : 'mdi-alert' }}</v-icon>
                    {{ $t('critical_items') }}
                </v-card-title>
                <v-card-subtitle class="pt-3 pb-3 text-h5 grey lighten-2">
                    {{ projectName }} {{ cameraNumber }}
                </v-card-subtitle>

                <v-card-text class="my-2">

                <v-row v-for="(item, idx) in health"
                        :key="idx">
                    <v-col cols="1">
                        <v-icon small :color="item.level === 2 ? 'error' : 'warning'">
                            {{ `mdi-${item.icon}` }}
                        </v-icon>
                    </v-col>
                    <v-col>
                        {{ $t(item.name).toUpperCase() }}
                    </v-col>
                    <v-col>
                        {{ item.value }}
                    </v-col>
                </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    :to="`/system/${systemId}`"
                >
                    {{ $t('go_to') }} {{ computerName }}
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-sheet v-if="showImage"
            :height="(imageWidth*0.75)+'em'">

            <a :href="'/system/' + systemId">
            <v-lazy
                :options="{
                    threshold: 0
                }"
                transition="fade-transition">

                <v-img :src="cImage"
                    lazy-src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                    :height="(imageWidth*0.75)+'em'"
                    @error="failedImage=true">

                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center">
                            <v-progress-circular
                                width="1"
                                indeterminate
                                color="orange lighten-2">
                            </v-progress-circular>
                        </v-row>
                    </template>
                </v-img>

            </v-lazy>
        </a>

        </v-sheet>

        <v-list-item @click="$router.push('/system/' + systemId).catch()">
            <v-list-item-avatar>
                <v-avatar :color="healthy ? 'grey lighten-2' : 'error'"
                        height="1.5em" width="1.5em" min-width="unset"
                        @click.prevent="infoVisible = true">
                    <span :class="[healthy ? 'dark--text' : 'white--text', 'subtitle-1']">
                        {{ cameraNumber }}
                    </span>
                </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
                <a :href="'/system/' + systemId">
                <div class="overline" style="font-size:1em !important;">
                    {{ computerName }}
                </div>
                </a>
            </v-list-item-content>
        </v-list-item>

        <v-list-item style="min-height:unset"
            @click="copyText(client)">
            <v-list-item-title class="caption my-0">
                {{ client }}
            </v-list-item-title>
        </v-list-item>

        <v-list-item @click="copyText(projectName)">
            <v-list-item-title class="subtitle-1 mb-1">
                {{ projectName }}
            </v-list-item-title>
        </v-list-item>

        <div v-if="!showImage" class="statusIcon">
            <v-icon v-if="type === 0" color="rgba(244, 67, 54, .2)">mdi-archive</v-icon>
            <v-icon v-else-if="type === 1" color="rgba(76, 175, 80, .4)">mdi-camera</v-icon>
            <v-icon v-else-if="type === 2" color="rgba(255, 152, 0, .3)">mdi-clock</v-icon>
            <v-icon v-else-if="type === 3" color="rgba(0, 0, 0, .15)">mdi-pause-box</v-icon>
        </div>
    </v-card>
</template>


<script>
import { getToken } from '../_helpers/auth'

export default {
    props: {
        imageWidth: Number,
        projectName: String,
        client: String,
        cameraNumber: Number,
        computerName: String,
        systemId: Number,
        type: Number,
        showImage: {
            default() {
                return true
            },
            type: Boolean,
        },
        healthData: {
            default() {
                return {}
            },
            type: Object,
        },
        healthy: {
            default() {
                return true
            },
            type: Boolean,
        },
    },

    data() {
        return {
            refreshInterval: '',
            failedImage: false,
            infoVisible: false,
            info: false,
            level: 0,
            health: {},
            updateDate: Date.now(),
        }
    },

    beforeDestroy() {
        clearInterval(this.refreshInterval)
    },

    computed: {
        cImage() {
            return this.failedImage
                ? 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                : `${process.env.VUE_APP_REST_DOMAIN}/static/images/thumbs/${this.systemId}.jpg?token=${getToken()}&ver=${this.updateDate}`
        },
    },

    methods: {
        copyText(text) {
            navigator.clipboard.writeText(text)
            return true
        },

        refreshImage() {
            // this.pImage = 'https://webcam.zeitraffer.org/ftp/thumbs/thumb_sys' + this.systemId + '.jpg?ver=' + Date.now()
        },

        onImgError(event) {
            this.failedImage = true
        },

        setHealth(healthData) {
            const data = []
            Object.keys(healthData).forEach(key => {
                data.push({
                    ...healthData[key],
                    ...{ type: key },
                })
            })
            data.sort((a, b) => a.level < b.level)
            this.health = data
            this.updateDate = Date.now()
            this.setLevel()
        },

        setLevel() {
            let level = 0
            if (this.health.image) {
                level = 2
            } else if (Object.keys(this.health).length > 0) {
                level = 1
            }

            this.level = level
        },
    },

    mounted() {
        this.setHealth(this.healthData)
    },

    watch: {
        healthData(val) {
            this.setHealth(val)
        },
    },
}
</script>

<style scoped>
    .tile-alert-badge.v-size--default {
        top: 8px;
        right: 8px;
    }

    .disabledBanner {
        position: absolute;
        top: 1.2em;
        left: 0em;
        transform: rotate(-45deg);
        font-size: 3em;
        z-index: 1;
        color: rgba(255, 0, 0,.4);
    }

    .statusIcon {
        position: absolute;
        bottom: .3em;
        right: .3em;
        z-index: 1;
    }
</style>
