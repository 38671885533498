<template>
    <v-card :elevation="0">
        <div class="spinner"
            v-if="page.spinner">
        </div>

        <v-tabs v-model="tab"
            @change="getSystemsForTab"
            grow>
            <v-tab>{{ $t('dashboard.active') }} ({{ systemsActiveCounts }})</v-tab>
            <v-tab>{{ $t('testing') }} ({{ systemsTestingCounts }})</v-tab>
            <v-tab>{{ $t('paused') }} ({{ systemsPausedCounts }})</v-tab>
            <v-tab>{{ $t('dashboard.inactive') }} ({{ systemsInactiveCounts }})</v-tab>
            <v-tab>{{ $t('All') }} ({{ allSystemLength }})</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <div id="tiles-container">
                    <v-container fluid grid-list-md class="pa-0">

                    <v-expansion-panels v-if="loaded"
                        v-model="activePanels"
                        accordion
                        flat
                        :elevation="0"
                        multiple>
                        <v-expansion-panel
                            class="expanel"
                            v-for="(item,i) in expandables"
                            :key="i">

                                <v-expansion-panel-header>
                                    <v-row justify-start>
                                        <v-icon medium
                                            :color="item.color">
                                            {{ item.icon }}
                                        </v-icon>
                                        <label class="theme--light ml-2 title">{{ item.title }}{{ getSystemsCount(item.type) > 0 ? ` (${getSystemsCount(item.type)})` : '' }}</label>
                                    </v-row>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content eager
                                    class="px-md-0">
                                    <v-data-iterator
                                        :ref="`iterator-${item.type}`"
                                        :id="`iterator-${item.type}`"
                                        :items="!loaded ? Array.from(Array(10).keys()) : item.type === 'error' ? systemsError : item.type === 'warning' ? systemsWarning : systemsOk"
                                        :options.sync="table.pagination"
                                        row
                                        hide-default-footer
                                        no-data-text=""
                                        :no-results-text="`${$t('no_matching')} - ${searchText} -`"
                                        :search="searchText"
                                        @current-items="(ci) => updateFilteredCount(ci.length, 0, i)"
                                        wrap>
                                        <template v-slot:item="props">

                                            <div class="tile-container">

                                                <v-skeleton-loader v-if="!loaded"
                                                    class="mx-auto"
                                                    width="210"
                                                    type="card"
                                                ></v-skeleton-loader>

                                                <SystemTile v-else
                                                    :id="`systemTile_${props.item.id}`"
                                                    :ref="`systemTile_${props.item.id}`"
                                                    :type="props.item.enabled"
                                                    :imageWidth="tileWidth"
                                                    :cameraNumber="parseInt(props.item.camNo)"
                                                    :computerName="props.item.computerName"
                                                    :projectName="props.item.projectName"
                                                    :systemId="props.item.id"
                                                    :client="props.item.clientName"
                                                    :timezone="props.item.timezone"
                                                    :healthData.sync="props.item.health" />
                                            </div>

                                        </template>
                                    </v-data-iterator>
                                </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                    </v-container>
                </div>
            </v-tab-item>

            <v-tab-item v-for="(selection, idx) in [systemsTesting, systemsPaused, systemsInactive, allSystems]"
                :key="idx">
                <div id="tiles-container">
                    <v-container fluid grid-list-md class="pa-0">

                        <v-data-iterator
                            :items="selection"
                            :options.sync="table.pagination"
                            row
                            hide-default-footer
                            no-data-text=""
                            :no-results-text="`No matching records found for - ${searchText} -`"
                            :search="searchText"
                            wrap>
                            <template v-slot:item="props">

                                <div class="tile-container">

                                    <SystemTile
                                        :id="`systemTile_${props.item.id}`"
                                        :ref="`systemTile_${props.item.id}`"
                                        :type="props.item.enabled"
                                        :imageWidth="tileWidth"
                                        :cameraNumber="parseInt(props.item.camNo)"
                                        :computerName="props.item.computerName"
                                        :projectName="props.item.projectName"
                                        :systemId="props.item.id"
                                        :client="props.item.clientName"
                                        :showImage="idx <= 1" />
                                </div>

                            </template>
                        </v-data-iterator>
                    </v-container>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import { rest } from '../_helpers'
import SystemTile from '@/components/SystemTile.vue'

const cron = require('node-cron')

const misc = require('../_helpers/misc')

export default {
    data() {
        return {
            tab: null,
            tabId: 0,
            systemsAll: [],
            systemsActive: [],
            systemsTesting: [],
            systemsPaused: [],
            systemsInactive: [],
            systemsTestingCounts: 0,
            systemsPausedCounts: 0,
            systemsInactiveCounts: 0,
            systemIdList: [],
            loaded: false,
            updateCron: null,
            updateCronStarted: false,
            fetchingSystems: false,
            fetchStatus: [],
            activePanels: [],
            systemsActiveCountsOk: 0,
            systemsActiveCountsWarning: 0,
            systemsActiveCountsError: 0,
            systemsOrder: [
                {
                    type: 'error',
                    title: this.$t('error'),
                    icon: 'mdi-alert-octagon',
                    color: 'error',
                },
                {
                    type: 'warning',
                    title: this.$t('warning'),
                    icon: 'mdi-alert',
                    color: 'warning',
                },
                {
                    type: 'ok',
                    title: this.$t('ok'),
                    icon: 'mdi-check-bold',
                    color: 'success',
                },
            ],
            tileWidth: 13,

            gaugeAll: {
                text: this.$t('cameras'),
                icon: 'mdi-camera-iris',
                count: 0,
                visible: true,
                items: [],
            },
        }
    },

    computed: {
        ...mapState({
            page: 'page',
            table: 'table',
        }),

        expandables() {
            const expandables = []
            this.systemsOrder.forEach(item => {
                if (this.getSystemsCount(item.type) >= 0 || !this.loaded) {
                    expandables.push(item)
                }
            })

            return expandables
        },

        searchText() {
            return this.table.searchText
        },

        allSystemLength() {
            return this.systemsActiveCounts + this.systemsTestingCounts + this.systemsPausedCounts + this.systemsInactiveCounts
        },

        allSystems() {
            return [this.systemsActive, this.systemsInactive].flat(1)
        },

        systemsOk() {
            return this.systemsActive.filter(x => x.enabled === 1 && x.healthLevel === 0)
        },

        systemsWarning() {
            return this.systemsActive.filter(x => x.enabled === 1 && x.healthLevel === 1)
        },

        systemsError() {
            return this.systemsActive.filter(x => x.enabled === 1 && x.healthLevel === 2)
        },

        systemsActiveCounts() {
            return this.systemsActiveCountsOk + this.systemsActiveCountsWarning + this.systemsActiveCountsError
        },
    },

    components: {
        SystemTile,
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setSortItems: 'table/setSortItems',
            setSorting: 'table/setSorting',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
            setGauges: 'page/setGauges',
        }),

        getSystemsCount(type) {
            if (type === 'error') return this.systemsActiveCountsError
            if (type === 'warning') return this.systemsActiveCountsWarning
            if (type === 'ok') return this.systemsActiveCountsOk
            return 0
        },

        updateFilteredCount(itemLength, tabId, idx) {
            if (this.loaded) {
                if (tabId === 0) {
                    if (idx === 0) this.systemsActiveCountsError = itemLength
                    else if (idx === 1) this.systemsActiveCountsWarning = itemLength
                    else if (idx === 2) this.systemsActiveCountsOk = itemLength

                    this.systemsTestingCounts = this.systemsTesting.filter(item => Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(this.searchText.toLowerCase()))).length
                    this.systemsPausedCounts = this.systemsPaused.filter(item => Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(this.searchText.toLowerCase()))).length
                    if (this.systemsInactive.length) {
                        this.systemsInactiveCounts = this.systemsInactive.filter(item => Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(this.searchText.toLowerCase()))).length
                    }
                }
            }
        },

        searchInObject(items, search, tabId, idx) {
            let filteredItems = items
            if (search !== '') {
                const kw = search.toLowerCase()
                filteredItems = items.filter(item => Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(kw)))
            }

            // this.updateFilteredCount(filteredItems.length, tabId, idx)
            return filteredItems // tabId === this.tabId ? filteredItems : items
        },

        filteredSystems(items, tabId, idx) {
            return this.searchInObject(items, this.searchText, tabId, idx)
        },

        getSystemsForTab(tabId) {
            this.tabId = tabId
        },

        refresh() {
            if (this.loaded && !this.fetchingSystems) {
                this.getSystemCounts()
                this.getSystems(true)
            }
        },

        getSystemCounts() {
            const payload = {
                attributes: ['enabled'],
                includes: [],
            }

            return rest.getRows('system', payload)
                .then(systems => {
                    let activeCounts = 0
                    let testingCounts = 0
                    let pausedCounts = 0
                    let inactiveCounts = 0

                    systems.forEach(system => {
                        if (system.enabled === 1) {
                            activeCounts += 1
                        } else if (system.enabled === 0) {
                            inactiveCounts += 1
                        } else if (system.enabled === 2) {
                            testingCounts += 1
                        } else if (system.enabled === 3) {
                            pausedCounts += 1
                        }
                    })

                    this.systemsActiveCountsOk = activeCounts // fake initial count, ignore states just full count on tab
                    this.systemsTestingCounts = testingCounts
                    this.systemsPausedCounts = pausedCounts
                    this.systemsInactiveCounts = inactiveCounts
                })
        },

        getSystems(refreshOnly) {
            const currentId = this.tabId
            if (this.fetchStatus.indexOf(currentId) < 0) {
                this.fetchStatus.push(currentId)
                if ((this.tabId === 0 && this.systemsActiveCounts <= 0)
                    || (this.tabId === 1 && this.systemsTesting.length <= 0)
                    || (this.tabId === 2 && this.systemsPaused.length <= 0)
                    || (this.tabId === 3 && this.systemsInactive.length <= 0)
                    || (this.tabId === 4 && this.allSystemLength <= 0)) {
                    this.showSpinner(true)
                }

                this.fetchingSystems = true

                const payload = {
                    attributes: [
                        'id',
                        'camNo',
                        ['computerData.computer.name', 'computerName'],
                        ['project.name', 'projectName'],
                        ['project.client.name', 'clientName'],
                        ['config.timezone', 'timezone'],
                        ['routerData.make', 'routerMake'],
                        ['housingData.make', 'housingMake'],
                        'enabled',
                        'voltage',
                        'pump',
                        'router',
                    ],
                    includes: [
                        {
                            type: 'project',
                            attributes: [],
                        },
                        {
                            type: 'computerData',
                            attributes: [],
                        },
                        {
                            type: 'routerData',
                            attributes: [],
                        },
                        {
                            type: 'errorData',
                            attributes: {
                                exclude: ['id', 'systemId', 'updatedAt', 'createdAt'],
                            },
                        },
                        {
                            type: 'config',
                            attributes: ['timezone'],
                        },
                        {
                            type: 'housingData',
                            attributes: [],
                        },
                        {
                            type: 'tag',
                            attributes: ['name'],
                            through: { attributes: [] },
                        },
                    ],
                }

                payload.where = { enabled: { $in: [1, 2, 3] } }

                const systemsActiveTmp = []
                const systemsTestingTmp = []
                const systemsPausedTmp = []

                this.systemIdList = []

                return rest.getRows('system', payload)
                    .then(systems => {
                        systems.forEach(system => {
                            system.tagData = system.tags.map(x => x.name).join(', ')

                            if (system.enabled === 1) {
                                const { health, level } = misc.healthCheck(system)
                                system.health = health
                                system.healthLevel = level

                                if (this.$refs[`systemTile_${system.id}`] && this.$refs[`systemTile_${system.id}`].length) {
                                    this.$refs[`systemTile_${system.id}`][0].setHealth(health)
                                }
                            } else if (system.enabled === 2) {
                                if (this.systemIdList.indexOf(system.id) < 0) {
                                    systemsTestingTmp.push(system)
                                }
                            } else if (system.enabled === 3) {
                                if (this.systemIdList.indexOf(system.id) < 0) {
                                    systemsPausedTmp.push(system)
                                }
                            }

                            systemsActiveTmp.push(system)

                            this.systemIdList.push(system.id)
                        })

                        this.systemsActive = systemsActiveTmp
                        this.systemsTesting = systemsTestingTmp
                        this.systemsPaused = systemsPausedTmp

                        // Update Gauges in AppBar
                        const errorCounts = this.systemsError.length
                        const warningCounts = this.systemsWarning.length
                        const okCounts = this.systemsOk.length

                        if (!refreshOnly) {
                            let count = 0
                            if (errorCounts >= 1) {
                                this.activePanels.push(count)
                            }
                            count += 1
                            if (warningCounts >= 1) {
                                this.activePanels.push(count)
                            }
                            count += 1
                            if (okCounts >= 1) {
                                this.activePanels.push(count)
                            }
                        }

                        if (errorCounts === 0 && this.activePanels.indexOf(0) >= 0) {
                            this.activePanels.splice(this.activePanels.indexOf(0), 1)
                        }
                        if (warningCounts === 0 && this.activePanels.indexOf(1) >= 0) {
                            this.activePanels.splice(this.activePanels.indexOf(1), 1)
                        }
                        if (okCounts === 0 && this.activePanels.indexOf(2) >= 0) {
                            this.activePanels.splice(this.activePanels.indexOf(2), 1)
                        }

                        const barGauges = []
                        if (okCounts) {
                            barGauges.push({
                                text: '',
                                color: 'success',
                                icon: 'mdi-check-bold',
                                count: okCounts,
                                visible: true,
                                click: () => {
                                    this.activePanels = [2]
                                    // setSorting([[item.click]])
                                },
                            })
                        }
                        if (warningCounts) {
                            barGauges.push({
                                text: '',
                                color: 'warning',
                                icon: 'mdi-alert',
                                count: warningCounts,
                                visible: true,
                                click: () => {
                                    this.activePanels = [1]
                                },
                            })
                        }
                        if (errorCounts) {
                            barGauges.push({
                                text: '',
                                color: 'error',
                                icon: 'mdi-alert-octagon',
                                count: errorCounts,
                                visible: true,
                                click: () => {
                                    this.activePanels = [0]
                                },
                            })
                        }

                        if (barGauges.length) {
                            this.gaugeAll.items = barGauges
                        }

                        if (this.gaugeAll.count !== systems.length) {
                            this.gaugeAll.count = okCounts + warningCounts + errorCounts
                            this.setGauges([this.gaugeAll])
                        }

                        if (!this.updateCronStarted) {
                            this.updateCronStarted = true
                            this.updateCron.start()
                        }

                        return true
                    })
                    .then(() => {
                        setTimeout(() => {
                            const systemsInactiveTmp = []
                            payload.where = { enabled: 0 }

                            rest.getRows('system/nostats', payload)
                                .then(systems => {
                                    systems.forEach(system => {
                                        if (this.systemIdList.indexOf(system.id) < 0) {
                                            systemsInactiveTmp.push(system)
                                        }
                                    })

                                    this.systemsInactive = systemsInactiveTmp
                                })
                        }, 2000)

                        return true
                    })
                    .catch(err => {
                        // this.updateCron.stop()
                        console.log('system update', err)

                        return false
                    })
                    .finally(() => {
                        this.showSpinner(false)

                        this.fetchingSystems = false
                        this.loaded = true

                        const index = this.fetchStatus.indexOf(currentId)
                        if (index > -1) {
                            this.fetchStatus.splice(index, 1)
                        }
                    })
            }

            return true
        },
    },

    mounted() {
        const second = Math.floor(Math.random() * 60)
        const cronTime = `${second} */5 * * * *`
        this.updateCron = cron.schedule(cronTime, () => {
            this.refresh()
        })
    },

    created() {
        this.setNew({
            type: 'system',
            title: 'System',
            modified: (idx, item) => {
                this.refresh()
                this.resetNew()
            },
        })

        this.setSorting([['project.client.name'], ['project.name'], ['camNo']])

        this.setSortItems([
            {
                sorting: [['project.client.name'], ['project.name'], ['camNo']],
                name: 'Project',
                icon: 'mdi-folder-outline',
            },
            {
                sorting: [['computerName']],
                name: 'Computer',
                icon: 'mdi-laptop',
            },
            {
                sorting: [['createdAt', true]],
                name: 'Creation Date',
                icon: 'mdi-calendar-star',
            },
            {
                sorting: [['disabledAt', false]],
                name: 'Finishing Date',
                icon: 'mdi-calendar-remove',
            },
        ])

        this.showSpinner(true)

        this.getSystemCounts()
        this.getSystems(false)

        // var notification = new Notification('Hallo', { body: 'What you want to do?!' });
        // ServiceWorkerRegistration.showNotification('Hallo', {body: 'What you want to do?!' });
    },

    beforeDestroy() {
        this.updateCron.stop()
    },
}
</script>


<style lang="stylus" scoped>
    .tile-container
        margin 1em
        float left

    #tiles-container
        display inline-flex
        width 100%

    .expanel
        border-bottom 1px solid #eee

    @media (max-width: 959px)
        .v-expansion-panel-content>>> .v-expansion-panel-content__wrap
            padding 0 !important
</style>
